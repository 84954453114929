<template>
  <div>
    <v-main>
      <home-toolbar/>
      <v-parallax :src="require('@/assets/bg.png')" height=600>
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12" class="text-left">
              <!-- <img :src="require('@/assets/ra-02.svg')" alt="" width="250"> -->
              <div class="overline">ALTERNATIVE MEDICINE PRACTITIONER</div>
              <h1 class="font-weight-thin">Quranic healing against Jinn possession, sorcery (sihr) and evil eye</h1>
              <h3 class="mt-2 body-1">Complete solution to all of humanity. Spiritual guidance and training against all forces of evil based on the holy Quran.</h3>
              <h3 class="font-weight-bold mt-3 white--text"><v-icon class="mr-2">mdi-map-marker</v-icon>Birmingham, United Kingdom and worldwide</h3>
              <v-btn plain rounded class="accent mt-5" large>Comming soon</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-parallax>
      <v-container>
      </v-container>
      <home-footer/>
    </v-main>
  </div>
</template>

<script>
import Toolbar from '@/components/Toolbar'
import Footer from '@/components/Footer'
export default {
  name: 'home',
  components: {
    'home-toolbar': Toolbar,
    'home-footer': Footer
  },
  data () {
    return {}
  }
}
</script>

<style scoped>
</style>
