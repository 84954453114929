import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
// import { sync } from 'vuex-router-sync'
import axios from 'axios'

Vue.config.productionTip = false

// sync(store, router)

new Vue({
  vuetify,
  router,
  axios,
  render: h => h(App)
}).$mount('#app')
