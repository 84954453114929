import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/symptoms',
    name: 'symptoms',
    component: Home
  },
  {
    path: '/treatment',
    name: 'treatment',
    component: Home
  },
  {
    path: '/resources',
    name: 'resources',
    component: Home
  },
  {
    path: '/contact',
    name: 'contact',
    component: Home
  },
  {
    path: '*',
    redirect: {
      name: 'home'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
