<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  data: () => ({}),
}
</script>

<style>

@font-face {
  font-family: "Awakenning";
  src: local("Awakenning"), url(./fonts/awakenning.ttf) format("truetype");
}

.myHeading {
  font-family: "Awakenning" !important;
  font-size: 40px;
  font-weight: 100 !important;
}

.myBody {
  font-family: "Awakenning" !important;
  font-size: 40px;
  font-weight: 100 !important;
}

</style>
