import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
          dark: {
            primary: '#E11A28',
            secondary: '#1B1720',
            accent: '#E11A28',
            error: '#E11A28',
            warning: '#ffeb3b',
            info: '#2196f3',
            success: '#66BB6A'
          },
          light: {
            primary: '#33CC66',
            secondary: '#606060',
            accent: '#33CC66',
            error: '#f44336',
            warning: '#ffeb3b',
            info: '#2196f3',
            success: '#66BB6A'
          }
        }
      },
      iconfont: 'md'
});
