<template>
  <div>
    <section class="mt-12">
      <v-divider></v-divider>
      <v-container class="py-12">
        <v-row align="center" justify="center" >
          <v-col cols="12" sm="6">
            <v-card class="pa-0 text-center" color="transparent" flat>
              <!-- <v-img :src="require('@/assets/logo.svg')" height="80" width="80" contain></v-img> -->
              <div class="overline">ALTERNATIVE MEDICINE PRACTITIONER</div>
              <h1 class="myHeading">Quranic Healing for all of mankind</h1>
              <h3 class="body-1">Complete solution to all of humanity. Spiritual guidance and training against all forces of evil based on the holy Quran.</h3>
              <!-- <v-text-field class="mt-6" label="Email address" filled rounded dense></v-text-field> -->
              <a href="https://www.instagram.com/ruqyahacademy/" style="text-decoration:none" target="_blank"><v-icon color="grey" large class="pa-6">fab fa-instagram</v-icon></a>
              <!-- <p class="body-2">By registering on our site, you agree to our <a href="/">Terms and Conditions</a> &amp;  <a href="/">Privacy Policy</a></p> -->
              <p>For any inquiries, please email us on <a href="mailto:ruqyahacademy@co.uk">ruqyahacademy@gmail.com</a></p>
              <p>We provide 100% Quran based treatment inspired by Shaykh Ben Halima Abderraouf. All our work is open source and transparent to the world.</p>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <v-footer class="py-4">
      <v-spacer></v-spacer>
      <div>Ruqyah Academy © {{ new Date().getFullYear() }}</div>
      <v-spacer></v-spacer>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: 'my-footer',
  components: {
  },
  data () {
    return {
      items: [
        { text: 'Real-Time', icon: 'mdi-clock' },
        { text: 'Audience', icon: 'mdi-account' },
        { text: 'Conversions', icon: 'mdi-flag' }
      ],
      helpItems: [
        { text: 'Contact us', icon: 'mdi-clock' },
        { text: 'Sign up', icon: 'mdi-account' },
        { text: 'My account', icon: 'mdi-flag' }
      ]
    }
  },
  methods: {
    navigateTo (route) {
      this.$router.push(route)
    }
  }
}
</script>

<style scoped>
</style>
