<template>
  <div>
    <v-navigation-drawer absolute temporary v-model="drawer">
      <v-row class="pa-3">
        <v-col cols="12" class="text-center">
          <!-- <a href="/"><img :src="require('@/assets/launcher-kds.png')" alt="" width="120"></a><br> -->
          <h3 class="pt-2">Ruqyah Academy</h3>
          <p>The Ultimate solution to mankind</p>
        </v-col>
      </v-row>
      <v-list dense>
        <v-list-item-group v-model="group" active-class="text--accent-4">
          <v-list-item @click="navigateTo({ name:'home'})">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item @click="navigateTo({ name:'symptoms'})">
            <v-list-item-title>Symptoms</v-list-item-title>
          </v-list-item>
          <v-list-item @click="navigateTo({ name:'treatment'})">
            <v-list-item-title>Treatment</v-list-item-title>
          </v-list-item>
          <v-list-item @click="navigateTo({ name:'resources'})">
            <v-list-item-title>Resources</v-list-item-title>
          </v-list-item>
          <v-list-item @click="navigateTo({ name:'contact'})">
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-row class="pa-3 mt-5">
        <v-col cols="12" class="text-center">
          <!-- <a href="https://play.google.com/store/apps/details?id=uk.co.softlab25.posandrokds" target="_blank"><img :src="require('@/assets/google-play-badge.png')" alt="" width="150"></a> -->
          <h4 class=" my-3 font-weight-black">Get in touch</h4>
          <a href="https://www.instagram.com/posandroapp/" style="text-decoration:none" target="_blank">
            <v-icon color="white" large class="mb-3">fab fa-instagram</v-icon>
          </a>
          <p>For any inquiries, <a href="mailto:ruqyahacademy@co.uk">ruqyahacademy@gmail.com</a></p>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-app-bar color="transparent">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-md-and-up"></v-app-bar-nav-icon>
      <router-link to="/" class="toolbarTitle">
        <v-toolbar-title>
          <!-- <img :src="require('@/assets/web-logo-small.svg')" alt="" width="150"> -->
        </v-toolbar-title>
      </router-link>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text @click="navigateTo({name:'home'})" class="d-none d-md-flex">Home</v-btn>
        <v-btn text @click="navigateTo({name:'symptoms'})" class="d-none d-md-flex">Symptoms</v-btn>
        <v-btn text @click="navigateTo({name:'treatment'})" class="d-none d-md-flex">Treatment</v-btn>
        <v-btn text @click="navigateTo({name:'resources'})" class="d-none d-md-flex">Resources</v-btn>
        <v-btn text @click="navigateTo({name:'contact'})" class="d-none d-md-flex">Get in touch</v-btn>
        <!-- <v-btn text @click="navigateTo({name:'basket'})">
          <v-badge left color="error" overlap :content="$store.state.order.lines.length" :value="$store.state.order.lines.length">
            <v-icon>mdi-cart</v-icon>
          </v-badge>
        </v-btn> -->
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        drawer: false,
        group: null,
      }
    },
    methods: {
      navigateTo(route) {
        if (this.$route.name !== route.name) {
          switch (route.name) {
            case 'logout':
              // this.$store.dispatch('setCustomer', null)
              // this.$store.dispatch('setToken', null)
              // his.$router.push({ name: 'menu' })
              break
            default:
              this.$router.push(route)
              break
          }
        }
      }
    }
  }
</script>

<style scoped>
  .toolbarTitle {
    text-decoration: none;
  }
</style>